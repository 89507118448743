/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font_path}roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('#{$font_path}roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font_path}roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font_path}roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$font_path}roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font_path}roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('#{$font_path}roboto-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
       url('#{$font_path}roboto-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font_path}roboto-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font_path}roboto-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font_path}roboto-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font_path}roboto-v18-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}