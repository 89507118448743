::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $placeholder_color;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $placeholder_color;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: $placeholder_color;
}

label {
	display: block;
	cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
input[type="password"],
select,
textarea {
	width: 100%;
	background: $form_bg;
	border: 1px solid $border_color;
	box-sizing: border-box;
	border-radius: $radius;
	// padding: $offset $offset * 1.5;
	padding: 6px $offset * 1.5 5px;
	outline: none;

	&:focus {
		border-color: $primary_color_highlight;
	}
}

select {
	padding: 8px $offset * 0.75 7px;
}

.form-row {
	margin-bottom: $offset * 2;
}

.ui-select {
	position: relative;
	display: inline-block;
	outline: none;

	&.small {
		font-size: 10px;
	}

	select {
		border: none;
		background: none;
		appearance: none;
		position: relative;
		z-index: 1;
		padding-right: $offset;
	}

	&::after {
		@include icomoon();

		content: "\e91c";
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.ui-sort {
	cursor: pointer;

	i {
		display: inline-block;
		margin-left: $offset * 2;
		color: $grey_mid;
	}
}

.job-desc-wrap {
	position: relative;

	@include font-size($font_size + 2);

	label {
		position: absolute;
		top: 50%;
		color: $primary_color;
		transform: translateY(-50%);
	}

	input[type="text"] {
		border-radius: 0;
		background: none;
		border: none;
		border-bottom: solid 2px $border_color;
		padding-left: $offset * 3;
		font-weight: $font_weight_medium;

		@include font-size($font_size + 2);
	}

	& > i {
		color: $primary_color;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -($offset * 2.5);
	}
}
