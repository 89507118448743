.files-list {
  padding: $offset * 2 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex: 0 0 32%;
  }
}

.file {
  background: $primary_color_bg;
  border: solid 1px $border_color;
  border-radius: $radius;
  padding: $offset $offset * 2;
  margin-bottom: $offset * 2;
  display: flex;
  align-items: center;
  position: relative;

  &-content {
    flex-shrink: 0;
    padding-left: $offset * 2;
  }

  .name {
    font-weight: $font_weight_medium;
    color: $primary_color_highlight;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }

  .order-name {
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    @include font-size(11);
  }

  .date {
    @include font-size(11);
  }

  & > i,
  & > .fancy-tooltip-wrap,
  & > .spinner {
    position: absolute;
    right: $offset * 2;
    @include font-size(20);
  }
}