.order-preview-table {
  display: flex;
  flex-direction: column;

  .order-preview-table-footer {
    margin-top: auto;

    .content-inner {
      display: flex;
      flex-direction: column;
      gap: $offset * 2;
    }
  }

  .order-preview-table-footer-info {
    color: $light_text_color;
    font-style: italic;
    line-height: 1.4;

    @include font-size(13);
  }

  .order-preview-table-footer-input {
    color: $primary_color;

    input {
      text-align: center;
      border-color: $primary_color;
      color: $primary_color;
      background: $primary_color_bg_darker;
      font-weight: $font_weight_medium;
    }
  }
}
