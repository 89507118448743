.inline-form {
	position: relative;
	
	input[type="submit"],
	button[type="submit"] {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		border: none;
		background: none;
		cursor: pointer;
		
		i {
			position: relative;
			top: 2px;
		}
	}
	
	input[type="text"] {
		padding-right: $offset * 4 !important;
	}
}