@mixin button() {
	border: none;
	display: inline-block;
	// font-weight: $font_weight_bold;
	transition: background .2s, color .1s;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	border-radius: $radius;
	
	&:hover {
		text-decoration: none;
	}
}

@mixin button-variant($bg: $primary_color, $color: $white) {
	background: $bg;
	color: $color;
	
	&:hover {
		background: darken($bg, 12%);
		color: $color;
	}
	
	&-ghost {
		background: transparent;
		border-color: $bg;
		color: $bg;
		
		&:hover {
			background: $bg;
			color: $color;
		}
	}
}

@mixin button-size($font_size: $font_size, $padding_top: 7px, $padding_left: $offset * 3) {
	padding: $padding_top $padding_left ($padding_top - 1);
	
	@include font-size($font_size);
	
	$padding_top: $padding_top - 1px;
	&-ghost {
		border-width: 1px;
		border-style: solid;
		padding: $padding_top $padding_left ($padding_top - 1);
	}
}

.button {
	@include button();
	@include button-variant();
	@include button-size();
	
	&-hightlight {
		@include button-variant($primary_color_highlight, $white);
	}

	&-white {
		@include button-variant($white, $primary_color_highlight);
	}
	
	&.no-button {
		@include button-variant($white, $base_text_color);

		&:hover,
		&.active {
			background: $white;
			color: $primary_color;
		}

		i {
			top: 0;
			font-size: 11px;
		}
	}
	
	// Sizes
	&-xl {
		@include button-size($font_size_big, 10px);
	}

    &.less-padding {
      padding-left: $offset * 1.5;
      padding-right: $offset * 1.5;
    }
	
	&-block {
		display: block;
		width: 100%;
		margin-bottom: $offset;
	}
	
	& + .button {
		margin-left: $offset * 2;
	}
	
	&[disabled] {
		background: rgba($grey_mid,0.35) !important;
		cursor: default;
	}

	&.collapse-left {
		padding-left: $offset * 1.5;
	}

	i {
		position: relative;
		top: 2px;

		@include font-size($font_size + 4);
	}
}