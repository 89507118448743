.transfersheet-page {
  .head {
    max-width: 400px;
    margin: 0 auto;
  }

  .ctabox {
    position: relative;
    min-height: 262px;

    h3 {
      padding-right: 290px;
    }

    .transfersheet-form-wrap {
      padding-right: 270px;
      margin-top: 70px;

      .forward-form {
        width: 225px;
      }
    }

    .svg {
      top: 50%;
      right: $offset * 4;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}

.forward-form {
  display: inline-block;
  vertical-align: top;
  margin-left: $offset;

  position: relative;
  border: solid 1px $white;
  border-radius: $radius;
  overflow: hidden;

  span {
    display: block;
    position: absolute;
    padding: ($offset - 2px) $offset * 2;
    left: 0;
    top: 0;
    bottom: 0;
  }

  button[type="submit"] {
    border: none;
    background: none;
    display: block;
    position: absolute;
    cursor: pointer;
    color: $white;
    padding: ($offset - 2px) $offset;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 1.6;

    @include font-size(18);
  }

  input {
    width: 100%;
    border: none;
    padding: ($offset - 2px)  $offset * 2;
    padding-left: $offset * 6;
    padding-right: $offset * 4;
    background: $primary_color_highlight;
    color: $white;

    &:focus + label,
    &:valid + label {
      display: none;
    }
  }

  label {
    background: $primary_color;
    display: block;
    position: absolute;
    padding: ($offset - 2px) $offset * 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;

    & > i {
      position: absolute;
      right: $offset * 1.5;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  $placeholder_color: rgba(255,255,255,0.5);

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholder_color;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder_color;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholder_color;
  }
}