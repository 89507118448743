.breadcrumb {
	h1 {
		color: $white;
	}
}

.tabs {
	display: flex;
	flex-direction: column;
	height: 100%;
	
	.tab-content {
		flex-grow: 1;
		// background: rgba($primary_color, 0.05);
	}
	
	.tab-navi {
		@include reset-list();
		
		display: flex;
		
		& > li {
			color: $grey_mid;
			flex: 1;
			text-align: center;
			padding: $offset / 2;
			cursor: pointer;
			border-bottom: solid 2px transparent;
			
			&.active,
			&:hover {
				color: $primary_color_highlight;
				border-bottom-color: $primary_color_highlight;
			}
		}
	}
}