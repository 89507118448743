.service-item {
  position: relative;
  margin: $offset * 2 $offset * 4;
  border: solid 1px $border_color;
  padding: $offset;
  // padding-left: $offset * 6;
  color: $primary_color_highlight;
  overflow: hidden;
  border-radius: $radius;

/*
  .fancy-check {
    position: absolute;
    left: $offset * 2;
    top: 50%;
    transform: translateY(-50%);
  }
*/
  & > .action {
     $dim: 24px;

     // border: solid 2px $primary_color;
     // background: $white;
     color: $primary_color;
     text-align: center;
     // display: block;
     // width: $dim;
     // line-height: $dim - 2px;
     // height: $dim;
     float: left;
     // border-radius: 50%;
     cursor: pointer;
     margin: $offset * 1.5 $offset * 2;
     margin-left: $offset;

     i {
       background: $white;
       border: solid 2px $primary_color;
       background: $white;
       color: $primary_color;
       border-radius: 50%;
       line-height: $dim - 4px;
       height: $dim;
       display: block;
       width: $dim;

       &.icon-minus {
         color: $color_error_light;
         border-color: $color_error_light;
         // line-height: $dim - 2px;
       }
     }

     @include font-size(10);

     &:hover {
       i {
         background: $primary_color;
         color: $white;

         &.icon-minus {
           background: $color_error_light;
         }
       }
     }

     & + .service-item-content {
       float: left;
       width: 70%;
     }
   }

  strong {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }

  &-content {
    width: 90%;
  }

  .favourite {
    position: absolute;
    right: $offset * 2;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.inactive {
      display: none;
      color: #b3b3b3;

      &:hover {
        color: $primary_color_highlight;
      }
    }
  }

  &:hover {
    .favourite.inactive { display: block; }
  }
}