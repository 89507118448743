@mixin alertbox() {
	padding: ($offset * 2);
	position: relative;
	overflow: hidden;
	margin-bottom: $offset * 2;
	
	.alertbox-msg {
		min-height: 30px;
		
		& > strong {
			display: block;
			
			@include font-size($font_size + 2);
		}
		
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin: 0;
			}
		}
	}
}

@mixin alertbox-variant($bg: $grey_light, $color: $base_text_color) {
	border: solid 1px $bg;
	background: lighten($bg, 62%);
	color: $bg;
}

.alertbox {
	@include alertbox();
	@include alertbox-variant();
	
	&.error {
		@include alertbox-variant($color_error, $white);
	}
	
	&.success {
		@include alertbox-variant($color_success, $white);
	}
}