$table_border_color: darken($border_color, 5%);

table {}

tr {}

th {
	// font-weight: $font_weight_medium;
	text-align: left;
}

.table-wrap {
	margin-bottom: $offset * 2;
	border-top: solid 1px $table_border_color;
	border-bottom: solid 1px $table_border_color;
	// border: solid 1px $border_color;

	table.table-bordered {
		border-bottom: none;
	}
}

.table {
	// margin-bottom: 20px;
	width: 100%;

	tr {}

	td, th {
		padding: $offset $offset * 2;
		// text-align: left;
		vertical-align: top;

		&.nobreak {
			white-space: nowrap;
		}
	}

	thead {
		tr {
			th, td {
				// color: $base_color;
				background: $primary_color_bg_darker;
				text-transform: uppercase;

				//@include font-size($font_size_small);
			}

			&:last-child {
				th, td {
					border-bottom: solid 1px $table_border_color;
				}
			}
		}
	}

	tbody {
		tr {
			th, td {
				border-top: solid 1px $table_border_color;

				//@include font-size($font_size - 1);
			}
		}
	}

	tfoot {}

	// sticky header
	&.sticky-header {
		thead {
			position: sticky;
			top: 0;

			@include z-index('table_header');

			th {
				border-top: none !important;
			}
		}
	}

	// Centering
	&.table-center {
		td, th {
			vertical-align: middle;
		}
	}

	// Zebra
	&.table-striped,
	&.zebra-striped {
		tbody{
			tr:nth-child(odd) th,
			tr:nth-child(odd) td,
			tr.odd th,
			tr.odd td {
				background: $light_bg;
			}

			tr:nth-child(even) th,
			tr:nth-child(even) td,
			tr.even th,
			tr.even td {}
		}
	}

	// Group Striping
	&.group-striped {
		tbody {
			border-bottom: solid 1px $table_border_color;
			tr td {
				border-bottom: none;
			}
			tr.odd td {
				background: $primary_color_highlight;
			}
			tr.odd + tr.even,
			tr.even + tr.odd {
				td {
					border-top: solid 1px $table_border_color;
				}
			}
		}
	}

	// Bordered
	&.table-bordered {
		border-bottom: solid 1px $table_border_color;

		thead {
			tr {
				th, td {
					border: solid 1px $table_border_color;
					border-bottom: none;
					position: relative;

					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 1px;
						background: $table_border_color;
					}

					& + th {
						&::before {
							content: "";
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
							width: 1px;
							background: $table_border_color;
						}
					}
				}
			}
		}

		tbody {
			tr {
				th, td {
					// border: solid 1px $border_color;
					
					&:first-child {
						border-left: solid 1px $table_border_color;
					}
					
					&:last-child {
						border-right: solid 1px $table_border_color;
					}
				}

				&:first-child {
					td {
						border-top: none;
					}
				}
			}
		}
	}

	// Hover Style on Table row
	&.table-hover {
		tbody{
			tr {
				&:hover {
					th, td {
						background: rgba($primary_color, 0.05);
					}
				}
			}
		}
	}
}