.avatar {
	background: $white;
	border-radius: 100%;
	overflow: hidden;
	width: 120px;
	height: 120px;
	margin: 0 auto $offset * 2;
	box-shadow: 0 0 15px rgba(0,0,0,0.1);

	&-45 {
		width: 45px;
		height: 45px;
	}
	
	img {
		height: auto;
		max-height: 100%;
		width: auto;
		max-width: 100%;
	}
}