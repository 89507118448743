.paging {
  a,
  span {
    display: inline-block;
    line-height: 1.25;
    text-decoration: none;
    border: solid 1px transparent;
    border-radius: 2px;
    padding: $offset;
    margin: 0 $offset / 4;
  }

  a.page:hover,
  span.current {
    background: $primary_color_bg_darker;
    border-color: darken($primary_color_bg_darker, 10%);
  }

  .prev,
  .next {
    i {
      position: relative;
      top: 1px;
    }
  }

  .first,
  .last,
  .next,
  .prev {
    i {
      top: 2px;
    }
  }

  .prev {
    margin-right: $offset;
  }

  .next {
    margin-left: $offset;
  }

  span {
    &.first,
    &.last,
    &.next,
    &.prev {
      cursor: default;
      opacity: 0.15;
    }
  }

  .first,
  .last {
    i + i {
      margin-left: -10px;
    }
  }
}