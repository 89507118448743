.message-stream {
  overflow-y: scroll;
}

.message-stream-timestamp {
  border-top: solid 1px #e2e2e2;
  text-align: center;
  font-size: 85%;
  margin: $offset * 6 0 $offset;

  &:first-child {
    margin-top: $offset;
  }

  span {
    background: #fff;
    display: inline-block;
    position: relative;
    top: -13px;
    padding: 0 20px;
  }
}

.message-stream-footer {
  overflow: hidden;

  textarea {
    margin-bottom: $offset / 2;
  }
}

.message-stream-attachment {
  float: left;
  color: $light_grey;
  cursor: pointer;
  padding: 7px 0 6px;
}