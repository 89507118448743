.content-wrap {
	position: relative;
	padding: $offset * 4 $offset * 2 $offset * 2;
	
	&::after {
		display: block;
		content: "";
		height: 260px;
		left: 0;
		right: 0;
		top: 0;
		background: $primary_color;
		position: absolute;
		z-index: -1;

		position: fixed;
		top: 48px;
	}
}

.container {
	margin: 0 auto;
	width: 100%;
	max-width: 1100px;
}

.content {
	background: $white;
	// border: 1px solid #B0C1D4;
	border-radius: $radius;
	min-height: 320px;
	// min-height: 70vh;
	overflow: hidden;
	
	&-inner {
		padding: $offset * 3.5 $offset * 4;

		&.collapse-vertical {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.collapse-bottom {
			padding-bottom: 0;
		}

		&.collapse-bottom-half {
			padding-bottom: $offset * 2;
		}

		&.collapse-top {
			padding-top: 0;
		}
	}
}

.flex-panel-content {
	flex: 1 0 auto;
}

.flex-panel-footer {
	flex-shrink: 0;

	&.multi-buttons {
		border-top: solid 1px $border_color;
		padding: $offset $offset * 4;
		text-align: left;

		& > .fancy-tooltip-wrap,
		& > span,
		a,
		& > button {
			display: inline-block;
			width: 50%;
			white-space: nowrap;
			outline: none;
		}

		& > .fancy-tooltip-wrap button {
			width: 100%;
			font-weight: $font_weight_medium;
		}
	}

	&.single-button {
		.button {
			margin: 0;
			border-radius: 0;
		}
	}
	& > .fancy-tooltip-wrap > span,
	& > span {
		color: $light_grey;
	}

	span,
	a {
		font-weight: $font_weight_medium;
	}
}

.back-btn-wrap {
	margin-top: -2px;
}