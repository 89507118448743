.tab-menu {
  @include reset_list();

  padding: ($offset * 2) 0;
  padding-left: $offset * 4;

  li {
    padding: $offset * 1.5 $offset * 2;
    padding-left: $offset * 6;
    background: transparent;
    margin-bottom: $offset * 2;
    font-weight: $font_weight_medium;
    text-transform: uppercase;
    border-radius: $radius 0 0 $radius;
    color: $light_grey;
    transition: $default-transition;
    cursor: pointer;
    position: relative;

    i {
      position: absolute;
      left: $offset * 2;
      top: 50%;
      transform: translateY(-50%);

      @include font-size(24);

      &.icon-order-communication { left: $offset * 1.5; }
      &.icon-paper { left: 12px; }
    }

    &.active,
    &:hover {
      background: $white;
      color: $base_text_color;

      i {
        color: $primary_color_highlight;
      }
    }
  }
}