.fancy-check {
	display: inline-block;
	line-height: 1;
	
	$dim: 18px;
	i {
		display: inline-block;
		background: $white;
		border: solid 1px $border_color;
		width: $dim;
		height: $dim;
		border-radius: $radius;
		position: relative;
		text-align: center;
		transition: $default-transition;
		margin-right: $offset;

		&:before {
			/* use !important to prevent issues with browser extensions that change fonts */
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			
			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			
			content: "\e909";
			color: $white;
			
			@include font-size(10);
		}
	}
	
	& > input {
		margin-left: -20px;
		opacity: 0;
		position: absolute;

		&:checked + i {
			border-color: $primary_color_highlight;
			background: $primary_color_highlight;
		}
	}
}