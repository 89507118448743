$body_bg: #F5F5F5;
$base_text_color: #33475B;
$light_text_color: #999999;
$base_text_color_hover: lighten($base_text_color, 30%);

$primary_color: #0E63B8;
$primary_color_highlight: #127EEB;
$primary_color_bg: #FBFCFE;
$primary_color_bg_darker: #EBF0F6;

$breadcrumb_link: #5BADFF;

$message_bg_user: #EDF5FC;

$light_bg: #F7F7F7;
$border_color: #E0E3EA;

$light_grey: #87A6CC;

$action_icons: #C4C4C4;

$tooltip_color: #515D80;
$tooltip_color_link: #8DD6FF;
