.action-icons {
  color: $action_icons;

  i {
    margin: 0 $offset / 2;
    cursor: pointer;

    @include font-size($font_size + 2);

    &:hover {
      color: $base_text_color;
    }
  }
}