html {
	font-size: 62.5%; /* Sets up the Base 10 stuff */
}

body {
	background: $body_bg;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;

	&.wait {
		cursor: wait !important;
	}
}