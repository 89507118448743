.service-list {
  border-top: solid 1px $border_color;

  &-inner {
    //overflow-y: scroll;
    //max-height: 300px;

    /*
    & > * {

      &:nth-child(even) {
        background: $white;
      }
    }
    */
  }
}