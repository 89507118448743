.breadcrumb {
	padding-top: 1px;
	padding-bottom: 2px;
	min-height: 42px;

	ul {
		@include reset-list();
		
		li {
			display: inline-block;
			margin: 0 $offset * 2;
			
			& + li {
				position: relative;
				padding-left: $offset * 2;
				
				&:before {
					/* use !important to prevent issues with browser extensions that change fonts */
					font-family: 'icomoon' !important;
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					
					/* Better Font Rendering =========== */
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					
					content: "\e91a";
					position: absolute;
					top: 45%;
					left: -($offset * 2.5);
					color: $white;
					transform: translateY(-50%);
					
					@include font-size(24);
				}
			}
		}
	}
	
	a,
	h1 {
		line-height: 1.2;
		color: $white;
		text-decoration: none;
		
		@include font-size(24);
	}
	
	a {
		color: $breadcrumb_link;

		&:hover {
			color: darken($breadcrumb_link, 10%);
		}
	}
}