.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000,0.35);
  color: $white;

  @include z-index('full_screen_loader');

  .inner {
    font-weight: $font_weight_medium;
    padding: $offset * 8;
    height: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    @include font-size(24);
  }
}

.progress {
  border: solid 1px $primary_color;
  background: $white;
  padding: 2px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: $offset;
  position: relative;
  width: 100%;

  .bar {
    background: $primary_color_highlight;
    color: #fff;
    font-size: 12px;
    padding: 2px 0;
    text-align: right;
    min-height: 20px;
    width: 80%;
    overflow: hidden;
    box-sizing: border-box;
    transition: .2s;
    position: relative;
  }
}