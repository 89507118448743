.header-notification {
	display: inline-block;
	position: relative;
	
	&:hover {
		.notification-list {
			display: block;
		}
	}
}

.notification-trigger {
	$dim: 20px;
	
	position: relative;
	
	span {
		border: solid 2px $primary_color_highlight;
		background: #DC0000;
		color: $white;
		display: block;
		width: $dim;
		height: $dim;
		line-height: $dim - 4px;
		text-align: center;
		border-radius: 100%;
		font-size: 11px;
		position: absolute;
		right: -8px;
		top: -6px;
	}
}

.notification-list {
	color: $base_text_color;
	display: none;
	min-height: 100px;
	width: 100px;
	top: 100%;
	right: 0;
	background: $white;
	position: absolute;
	box-shadow: 0 0 10px rgba(0,0,0,0.15);
}