body {
	color: $base_text_color;
	font-family: $font_family;
	line-height: $line_height_factor;
	font-weight: $font_weight_base;
	
	@include font-size($font_size);
}

h1,h2,h3 {
	line-height: $line_height_factor_headlines;
	color: $primary_color;
}

h3 {
	@include font-size(24);
}

.sticky-headline {
	background: $white;
	padding: $offset / 2 0;
	position: sticky;
	top: 0;

	@include z-index(sticky_headline);
}

.has-subline {
	margin: $offset * 4 0;

	&::after {
		content: "";
		display: block;
		background: $primary_color;
		height: 3px;
		width: 55px;
		margin: $offset * 2 auto;
	}
}

.ui-link,
a {
	cursor: pointer;
	color: $primary_color_highlight;
	
	&:hover {
		color: $primary_color_highlight;
		text-decoration: underline;
	}
}

a.reset-link {
	@include reset_link();
}

a.block-link {
	@include block_link();
}

strong {
	font-weight: $font_weight_medium;
}

.primary-color {
	color: $primary_color;
}

.primary-color-highlight {
	color: $primary_color_highlight;
}

.action-disabled {
	opacity: 0.2;
	cursor: inherit !important;
}

hr {
	border: none;
	margin: $offset * 4 0;
	height: 1px;
	background: $border_color;
}

dl {
	overflow: hidden;

	dt, dd {
		margin-bottom: $offset / 2;
	}
	
	dt {
		float: left;
		clear: both;
	}
	
	dd {
		float: right;
		text-align: right;
	}
}

.txtcenter { text-align: center; }
.nowrap { white-space: nowrap; }