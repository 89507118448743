.filter {
	// overflow: hidden;
	margin-bottom: $offset * 2;

	&-row {
		overflow: hidden;

		& + .filter-row {
			margin-top: $offset;
		}
	}
}

.filter-item {
	float: left;
}

.filter-status {
	& > * {
		display: inline-block;
		border-radius: $radius;
		background: $grey_light;
		padding: 2px 8px 1px;
		cursor: pointer;

		i {
			position: relative;
			top: 1px;
		}

		& + * {
			margin-left: $offset;
		}

		&.active {
			color: $white;
			background: $primary_color;
		}
	}
}