.order-detail {
  background: $white;
  display: flex;
  width: 720px;
  border-radius: $radius;

  & > * {
    flex: 1;
  }

  &-head {
    text-align: center;
    border-bottom: solid 1px $border_color;

    strong {
      @include font-size(18);
    }

    .action-icons {
      padding-top: $offset * 2;

      @include font-size(20);
    }
  }

  &-left {
    background: $primary_color_bg_darker;
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
  }

  &-right {
    & > * {
      height: 100%;
    }

    .service-list.scrollable {
      overflow-y: inherit !important;
    }
  }
}