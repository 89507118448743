@mixin reset_link() {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

@mixin block_link() {
  display: block;

  @include reset_link();
}