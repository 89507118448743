@font-face {
  font-family: 'icomoon';
  src:  url('#{$icon_font_path}icomoon.eot?yuulrg');
  src:  url('#{$icon_font_path}icomoon.eot?yuulrg#iefix') format('embedded-opentype'),
        url('#{$icon_font_path}icomoon.ttf?yuulrg') format('truetype'),
        url('#{$icon_font_path}icomoon.woff?yuulrg') format('woff'),
        url('#{$icon_font_path}icomoon.svg?yuulrg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock-o:before {
  content: "\e929";
}
.icon-star-half-empty:before {
  content: "\e91e";
}
.icon-bookmark-o:before {
  content: "\e91f";
}
.icon-star-o:before {
  content: "\e920";
}
.icon-bookmark:before {
  content: "\e921";
}
.icon-star-half:before {
  content: "\e922";
}
.icon-star:before {
  content: "\e923";
}
.icon-refresh-cw:before {
  content: "\e934";
}
.icon-refresh-ccw:before {
  content: "\e93b";
}
.icon-favorite-outline:before {
  content: "\e924";
}
.icon-favorite:before {
  content: "\e925";
}
.icon-arrow-right:before {
  content: "\e915";
}
.icon-arrow-left:before {
  content: "\e916";
}
.icon-arrow-up:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e918";
}
.icon-chevron-up:before {
  content: "\e919";
}
.icon-chevron-right:before {
  content: "\e91a";
}
.icon-chevron-left:before {
  content: "\e91b";
}
.icon-chevron-down:before {
  content: "\e91c";
}
.icon-refresh:before {
  content: "\e93c";
}
.icon-refresh1:before {
  content: "\e93d";
}
.icon-view-show:before {
  content: "\e928";
}
.icon-close-solid:before {
  content: "\e90a";
}
.icon-close-outline:before {
  content: "\e90b";
}
.icon-minus-solid:before {
  content: "\e90c";
}
.icon-minus-outline:before {
  content: "\e90d";
}
.icon-add-solid:before {
  content: "\e90e";
}
.icon-add-outline:before {
  content: "\e90f";
}
.icon-information-outline:before {
  content: "\e910";
}
.icon-select-arrows:before {
  content: "\e911";
}
.icon-heart-outline:before {
  content: "\e912";
}
.icon-heart:before {
  content: "\e913";
}
.icon-cloud-check:before {
  content: "\e93e";
}
.icon-cloud:before {
  content: "\e93e";
}
.icon-synced:before {
  content: "\e93e";
}
.icon-spinner:before {
  content: "\e93f";
}
.icon-loading:before {
  content: "\e93f";
}
.icon-loading-wheel:before {
  content: "\e93f";
}
.icon-refresh2:before {
  content: "\e93f";
}
.icon-repeat:before {
  content: "\e93f";
}
.icon-busy:before {
  content: "\e93f";
}
.icon-wait:before {
  content: "\e93f";
}
.icon-arrow:before {
  content: "\e93f";
}
.icon-loop:before {
  content: "\e940";
}
.icon-repeat1:before {
  content: "\e940";
}
.icon-player:before {
  content: "\e940";
}
.icon-reload:before {
  content: "\e940";
}
.icon-refresh3:before {
  content: "\e940";
}
.icon-update:before {
  content: "\e940";
}
.icon-synchronize:before {
  content: "\e940";
}
.icon-arrows:before {
  content: "\e940";
}
.icon-minus:before {
  content: "\e907";
}
.icon-plus:before {
  content: "\e908";
}
.icon-duplicate:before {
  content: "\e927";
}
.icon-documents:before {
  content: "\e927";
}
.icon-cog:before {
  content: "\e91d";
}
.icon-checkmark:before {
  content: "\e909";
}
.icon-tick:before {
  content: "\e909";
}
.icon-correct:before {
  content: "\e909";
}
.icon-accept:before {
  content: "\e909";
}
.icon-ok:before {
  content: "\e909";
}
.icon-action-close:before {
  content: "\e92a";
}
.icon-action-copy:before {
  content: "\e92b";
}
.icon-action-delete:before {
  content: "\e92c";
}
.icon-action-edit:before {
  content: "\e92d";
}
.icon-action-send:before {
  content: "\e92e";
}
.icon-action-view:before {
  content: "\e92f";
}
.icon-attachment:before {
  content: "\e930";
}
.icon-download:before {
  content: "\e931";
}
.icon-helpcenter:before {
  content: "\e932";
}
.icon-info:before {
  content: "\e933";
}
.icon-menu-down:before {
  content: "\e935";
}
.icon-menu-settings:before {
  content: "\e936";
}
.icon-menu-up:before {
  content: "\e937";
}
.icon-menu:before {
  content: "\e938";
}
.icon-order-communication:before {
  content: "\e939";
}
.icon-order-detail:before {
  content: "\e93a";
}
.icon-bell:before {
  content: "\e900";
}
.icon-grid:before {
  content: "\e901";
}
.icon-lab:before {
  content: "\e902";
}
.icon-magnifying-glass:before {
  content: "\e903";
}
.icon-paper:before {
  content: "\e904";
}
.icon-play:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e906";
}
.icon-remove:before {
  content: "\e926";
}
.icon-delete:before {
  content: "\e926";
}
.icon-pen:before {
  content: "\e914";
}
