.edit-audit-form {
  &-inner {
    display: flex;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;

      & + div {
        border-left: solid 1px $border_color;
      }
    }
  }
}

.edit-audit-form-wrap {
  & > .w-50 {
    width: 400px;
  }
}