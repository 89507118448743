$spinner-color: $primary_color !default;
$spinner-background-color: $light_grey !default;
$spinner-stroke: 2px !default;
$spinner-diameter: 40px !default;
$spinner-sides: 1 !default;

@mixin spinner(
  $color: $spinner-color,
  $background-color: $spinner-background-color,
  $stroke: $spinner-stroke,
  $diameter: $spinner-diameter,
  $sides: $spinner-sides
) {
  border: $stroke solid $background-color;
  font-size: $diameter;
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: .5em;
  animation: spin 1s linear infinite;
  border-top-color: $color;
  @if $sides >= 2 {
    border-right-color: $color;
  }
  @if $sides >= 3 {
    border-bottom-color: $color;
  }
}

.spinner {
  @include spinner();

  &-small {
    font-size: 14px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-wrap {
  position: relative;

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -7px;
    margin-left: -7px;

    & + span {
      visibility: hidden;
    }
  }
}