.services-overview {
  .content {
    display: flex;

    & > div {
      flex: 1;

      & + div {
        border-left: solid 1px $border_color;
      }
    }
  }
}