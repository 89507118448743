.modal {
  position: fixed;
  left: 0;
  top: 48px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $offset * 4;

  @include z-index('modal');

  &-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.45);

    @include z-index('modal_overlay');
  }

  &-inner {
    position: relative;
    // max-width: 990px;
    max-width: 1050px;
    max-height: calc(100vh - 108px);
    min-height: calc(100vh - 108px);

    @include z-index('modal_inner');
  }

  &-close {
    position: absolute;
    top: 0;
    cursor: pointer;
    right: -($offset * 9);
    line-height: 1;
    text-align: center;
    color: $white;

    $icon_dim: 40px;
    i {
      display: inline-block;
      background: $white;
      // padding: $offset $offset ($offset - 2px);
      width: $icon_dim;
      height: $icon_dim;
      line-height: $icon_dim;
      color: $primary_color;
      border-radius: $radius;
    }

    small {
      display: block;
      padding-top: 6px;

      @include font-size(12);
    }

    @include font-size(22);
    @include z-index('modal_close');
  }
}