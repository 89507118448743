.border-top {
  border-top: solid 1px $border_color;
}

.border-radius {
  border-radius: $radius;
}

.offset-top-half { margin-top: $offset / 2; }
.offset-top-1 { margin-top: $offset; }

.offset-bottom-1 { margin-bottom: $offset; }