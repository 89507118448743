$body_bg: #E5E5E5 !default;
$primary_color: red !default;

$base_text_color: #555 !default;
$white: #fff !default;

// greys
$grey_dark: #33475B !default;
$grey_mid: #999 !default;
$grey_light: #eee !default;

$grey_dark_bg: #DADADA;


// status
$color_info: #0C6799 !default;
$color_info_bg: #C7EBFF !default;
$color_error: #7A1818 !default;
$color_error_bg: #F9AEAE !default;
$color_error_light: #EB5757 !default;
$color_warning: #73601D !default;
$color_warning_bg: #FFF6A6 !default;
$color_warning_light: #FFF6A6 !default;
$color_success: #287A0C !default;
$color_success_bg: #C1F9AE !default;

// theme
$form_bg: #FBFCFE !default;
$border_color: $grey_mid !default;
$placeholder_color: $grey_mid !default;