.home {
	background: $white;
	display: flex;
	min-height: 100vh;
	
	.logo {
		display: block;
		width: 185px;
		height: auto;
		margin: 0 auto;
	}
	
	.left {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0 $offset * 5;
	}
	
	.inner {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		width: 320px;
		max-width: 320px;
		margin: 0 auto;
		
		img {
			height: auto;
			width: 185px;
		}
	}
	
	.right {
		width: 55%;
		background-color: $primary_color_highlight;
		position: relative;
		background-size:400px;
		background-repeat: repeat;
		
		&-footer {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			//height: 45%;
			min-height: 230px;
			background: $primary_color;
			color: rgba(199, 211, 252, 0.7);
			padding: $offset * 4 $offset * 6;

			.h1 {
				font-weight: $font_weight_medium;
				margin-bottom: $offset;
				color: $white;

				@include font-size(20);
			}

			& > img {
				position: absolute;
				bottom: 100%;
				left: 0;
				right: 0;
				margin: 0 auto;
				margin-bottom: -30px;
				max-width: 400px;
				height: auto;
			}
		}
	}
	
	.footer {
		text-align: center;
		padding: $offset * 2;
		
		&-bottom {
			color: $grey_mid;
			margin-top: $offset * 4;
			
			a {
				color: $grey_mid;
			}
		}
	}
}