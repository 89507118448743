.row-action {
  text-align: right;
  opacity: 0;
  transition: $default-transition;

  i {
    cursor: pointer;
    position: relative;
    top: 4px;
    display: inline-block;

    @include font-size(13);

    & + i {
      margin-left: 6px;
    }
  }
}

tr.tr-hover-class,
tr:hover {
  .row-action {
    opacity: 1;
  }
}

.list-detail-view {
  background: $white;
  position: fixed;
  right: 0;
  top: $offset * 8;
  bottom: 0;
  max-width: 450px;
  width: 100%;
  border-top-left-radius: $radius;
  padding-top: $offset * 6;

  @include z-index(order_datail_view);

  .close-trigger {
    cursor: pointer;
    position: absolute;
    display: block;
    left: $offset * 4;
    top: $offset * 4;

    @include font-size(26);
  }

  &-overlay {
    display: block;
    background: rgba(0,0,0, 0.5);
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    @include z-index(order_datail_view_overlay);
  }
}