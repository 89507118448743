.tooltip {
  // position: relative;
  display: inline-block;
  cursor: pointer;

  // @include z-index('tooltip');
  
  a {
    color: $tooltip_color_link;
    text-decoration: underline;

    &:hover {
      color: darken($tooltip_color_link,25%);
    }
  }

  &-msg-wrap {
    display: none;
    position: absolute;
    //bottom: 90%;
    padding-bottom: $offset;
    transform: translate(-45%,-95%);
    //left: 50%;

    @include font-size($font_size - 2);
    @include z-index('tooltip');
  }

  &-msg {
    padding: $offset $offset * 2;
    border-radius: $radius;
    background: $tooltip_color;
    text-align: left;
    white-space: nowrap;
    color: $white;
    width: 100%;
    position: relative;

    &::after {
      @include triangle(bottom, $tooltip_color, 1rem);

      position: absolute;
      top: 100%;
      transform: translateX(-50%);
      left: 50%;
    }
  }

  &.max-width {
    .tooltip-msg {
      white-space: normal;
      width: 230px;
    }
  }

  &.tooltip-visible,
  &:hover {
    .tooltip-msg-wrap {
      display: block;
    }
  }

  &.tooltip-visible {
    position: absolute;

    .tooltip-msg-wrap {
      position: relative !important;
    }
  }
}

[data-tooltip] {
  display: inline-block;
  position: relative;
  cursor: pointer;
  // @include z-index('tooltip');

 &::before,
 &::after {
   display: none;
 }

  &:hover {
    &::before,
    &::after {
      display: block;
    }
  }

 &::before {
   padding: $offset $offset * 2;
   border-radius: $radius;
   background: $tooltip_color;
   text-align: left;
   white-space: nowrap;
   color: $white;
   position: absolute;
   bottom: 90%;
   margin-bottom: $offset;
   transform: translateX(-50%);
   left: 50%;
   content: attr(data-tooltip);

   @include font-size($font_size - 2);
   @include z-index('tooltip');
 }

  &::after {
    @include triangle(bottom, $tooltip_color, 1rem);

    position: absolute;
    top: -$offset;
    transform: translateX(-50%);
    left: 50%;
  }
}

.fancy-tooltip-wrap {
  display: inline-block;
}

.fancy-tooltip {
  position: absolute;
  transform: translate(-50%,-100%);
  transition: $default-transition;
  visibility: hidden;
  max-width: 200px;
  padding-bottom: $offset / 2;

  .fancy-tooltip-msg {
    visibility: hidden;
    opacity: 0;
    transition: .2s $default-easing;
    transform-origin: bottom center;
    transform: scale(0.2);
    display: block;
  }

  &.active {
    .fancy-tooltip-msg {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  @include font-size($font_size - 2);
  @include z-index('tooltip');

  a {
    color: $tooltip_color_link;
    text-decoration: underline;

    &:hover {
      color: darken($tooltip_color_link,25%);
    }
  }

  &-msg {
    padding: $offset $offset * 2;
    border-radius: $radius;
    background: $tooltip_color;
    text-align: left;
    // white-space: nowrap;
    color: $white;
    width: 100%;
    position: relative;

    &::after {
      @include triangle(bottom, $tooltip_color, 1rem);

      position: absolute;
      top: 100%;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}
.my-node {background: yellow !important;}
.my-node-enter {
  background: red !important;
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}