.panel-trigger {
	background: $white;
	position: fixed;
	left: 0;
	top: 0;
	color: $primary_color;
	cursor: pointer;
	text-transform: uppercase;
	padding: $offset * 1.5 $offset * 3;
	overflow: hidden;
	
	@include z-index(panel_trigger);
}

.panel-trigger-icon {
	height: 2px;
	width: 16px;
	position: relative;
	background: $primary_color;
	display: block;
	float: left;
	margin-right: $offset;
	top: 10px;
	transition: background 0.4s;
	
	&::before,
	&::after {
		content: "";
		height: 2px;
		background: $primary_color;
		position: absolute;
		width: 100%;
		top: -5px;
		
		transition-duration: 0.3s, 0.3s, 0.3s;
		transition-delay: 0.3s, 0s, 0s;
		transition-property: top, transform, width;
	}
	
	&::after {
		top: 5px;
		width: 60%;
	}
}

.panel-profile {
	text-align: center;
}

.panel-item {
	padding: $offset * 4 $offset * 3 $offset * 6;
	
	& + .panel-item {
		border-top: solid 1px $border_color;
	}
}

.panel {
	display: block !important;
	background: $white;
	color: $base_text_color;
	bottom: 0;
	position: fixed;
	top: 0;
	width: $panel_width;

	@include z-index(panel);
	
	&-inner {
		overflow-y: scroll;
		height: 100%;
		padding-bottom: 100px;
	}
	
	&-left {
		left: 0;
		margin-left: -$panel_width;
		padding-top: $offset * 6;
	
		transition: margin-left 0.4s;
	}
	
	&-right {
		right: 0;
		margin-right: -$panel_width;
	
		transition: margin-right 0.4s;
	}
}

.app {
	position: relative;
	left: 0;
	transition: left 0.4s;
}

body.panel-active-left {
	.panel-trigger-icon {
		background: none;
		
		&::before,
		&::after {
			transition-delay: 0s, 0.3s, 0s;
		}

		&::before {
			top: 0;
			transform: rotate(45deg);
		}
		&::after {
			top: 0;
			width: 100%;
			transform: rotate(-45deg);
		}
	}
	
	.panel-left {
		margin-left: 0;
	}
	
	.app {
		left: $panel_width;
	}
	
	/*
	header {
		left: $main_navi_mobile_offset;
		left: $body_mobile_offset;
		
		#fixed-header {
			margin-left: $main_navi_mobile_offset;
			margin-left: $body_mobile_offset;
		}
	}
	
	#main-wrap {
		left: $main_navi_mobile_offset;
		left: $body_mobile_offset;
	}
	*/
}

.panel-menu {
	ul {
		@include reset_list();
	}
	
	a {
		display: block;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: $font_weight_medium;
		position: relative;
		padding: $offset * 1.5;
		padding-left: $offset * 5;
		color: $grey_mid;
		
		i {
			left: $offset * 2;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			
			@include font-size($font_size_big + 4);
		}
		
		&.active,
		&:hover {
			color: $base_text_color;
			
			i {
				color: $primary_color_highlight;
			}
		}
	}
}