@mixin infobox() {
	padding: ($offset * 2);
	position: relative;
	overflow: hidden;
	margin-bottom: $offset * 2;
	border-radius: $radius;
	
	.infobox-msg {
		min-height: 30px;
		
		& > strong {
			display: block;
			
			@include font-size($font_size + 2);
		}
		
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				margin: 0;
			}
		}
	}
}

@mixin infobox-variant($bg: $grey_light, $color: $base_text_color) {
	background: $bg;
	color: $color;
}

.infobox {
	@include infobox();
	@include infobox-variant();
	
	&.infobox-error,
	&.error {
		@include infobox-variant($color_error, $white);
	}

	&.infobox-success,
	&.success {
		@include infobox-variant($color_success, $white);
	}
}