@mixin status-variant($color: $grey_dark, $bg: organe) {
	background: $bg;
	color: $color;
}

.status {
	display: inline-block;
	line-height: 1.4;
	padding: $offset / 2 $offset;
	// padding-right: $offset;
	border-radius: $radius;

	@include status-variant();

	&-info { 	@include status-variant($color_info, $color_info_bg); }
	&-warning { @include status-variant($color_warning, $color_warning_bg); }
	&-error { 	@include status-variant($color_error, $color_error_bg); }
	&-success { @include status-variant($color_success, $color_success_bg); }

	& > .fancy-tooltip-wrap > i,
	& > .tooltip,
	& > i {
		position: relative;
		top: 2px;
		margin-left: 2px;
	}

	i.icon-loop {
		font-size: 10px;
		top: 0px;
		margin-left: 6px;
	}

	i.icon-synced {
		margin-left: 3px;
	}
}

.status-incomplete {
	border: solid 1px $color_error_light;
	background: none;
	color: $color_error_light;
	padding: ($offset / 2 - 2px) ($offset - 2px);
}
