.header {
  padding-left: 0;
}

.header-logo {
  float: left;
  // margin-right: $offset * 2;

  img {
    width: auto;
    height: 46px;
  }

  a {
    display: block;
    background: $white;
    padding: 6px;
  }
}