.header-usermenu {
  position: relative;

  &:hover {
    .header-usermenu-trigger {
      background: #47A3FF;
    }

    .header-usermenu-dd {
      display: block;

      visibility: visible;
      opacity: 1;
      transform: rotateX(0);
    }
  }
}

.header-usermenu-trigger {
  padding: $offset * 2.25 $offset * 4 $offset * 2;
  background: transparent;
  transition: .2s;
}

.header-usermenu-dd {
  display: none;
  position: absolute;
  padding-top: $offset / 2;
  right: 0;

  visibility: hidden;
  opacity: 0;
  transition: .4s $default-easing;
  transform-origin: 0 0;
  transform: rotateX(-20deg);
  display: block;

  .header-usermenu-dd-inner {
    background: $white;
    color: $base_text_color;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    position: relative;
    min-width: 200px;

    &::before {
      @include triangle(top, $white, 2rem);

      position: absolute;
      top: -$offset * 1.5;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}

.header-usermenu-row {
  padding: $offset * 1.5 $offset * 2;

  & + .header-usermenu-row {
    border-top: solid 1px $border_color;
  }
}