$skeleton_base_color: #eaeaea;
$skeleton_shine_color: rgba(200,200,200,0.8);
$animation-duration: 2.0s;

@mixin background-gradient($base: $skeleton_base_color, $shine: $skeleton_shine_color) {
  background-image: linear-gradient(90deg, $base 0px, $shine 40px, $base 80px);
  background-size: 600px;
}

@keyframes shine-lines {
  0% { background-position: -100px }
  40%, 100% { background-position: 140px }
}

.skeleton-item {
  margin: $offset / 2 0;
  width: 140px;
  height: $offset * 2;
  border-radius: $radius;

  @include background-gradient();
  animation: shine-lines $animation-duration infinite ease-out;
}

.skeleton-item-list-row .skeleton-item {
  margin: ($offset / 2 + 2px) 0 ($offset / 2);
}