.audit-plan {
  border: solid 1px $border_color;
  margin: $offset * 2 $offset * 4;
  border-radius: $radius;
  margin-bottom: $offset;

  &-head {
    font-weight: $font_weight_medium;
    color: $primary_color;
    padding: $offset * 1.5 $offset * 2;
    position: relative;

    .edit-trigger {
      display: none;
      cursor: pointer;
      margin-left: $offset;
      position: relative;
      top: 2px;
      color: $grey_mid;

      i {
        line-height: 1;

        @include font-size(18);
      }

      &:hover {
        color: $primary_color;
      }
    }

    &:hover {
      .edit-trigger {
        display: inline-block;
      }
    }

    & > .action {
      $dim: 24px;

      // border: solid 2px $primary_color;
      // background: $white;
      text-align: center;
      // display: block;
      // width: $dim;
      // line-height: $dim - 2px;
      // height: $dim;
      float: left;
      // border-radius: 50%;
      cursor: pointer;
      margin: 0 $offset;
      margin-left: 0;

      i {
        background: $primary_color;
        color: $white;
        border-radius: 50%;
        line-height: $dim;
        height: $dim;
        display: block;
        width: $dim;
      }

      @include font-size(10);
    }
  }

  .open-trigger {
    position: absolute;
    top: 50%;
    right: $offset * 1.5;
    transform: translateY(-50%);
    cursor: pointer;

    @include font-size(20);
  }

  &-content {
    background: $primary_color_bg;
    border-top: solid 1px $border_color;
    max-height: 170px;
    overflow-y: scroll;
    transition: $default-transition;

    ul {
      @include reset_list();

      li {
        padding: $offset * 1.5 $offset * 2;

        & + li {
          border-top: solid 1px $border_color;
        }
      }
    }
  }
}