$offset: 8px !default;

// font
// ------------------------------
$font_normal: 'Arial', 'Helvetica', sans-serif !default;

$font_weight_light: 100 !default;
$font_weight_normal: 400 !default;
$font_weight_medium: 600 !default;

$font_family: $font_normal !default;
$font_size: 14 !default;
$font_weight_base: $font_weight_normal !default;
$line_height_factor: 1.7 !default;
$line_height_factor_headlines: 1.2 !default;


// Animations
// ------------------------------
$default-easing: cubic-bezier(0.25, 1, 0.2, 1);
$default-timing: 0.7s;
$default-transition: $default-timing $default-easing;