.ctabox {
  background: $primary_color;
  color: $white;
  border-radius: $radius * 3;
  max-width: 800px;
  margin: $offset * 4 auto;
  box-shadow: 0px 4px 24px rgba(16, 113, 209, 0.33);

  h2,h3 {
    color: $white;

    small {
      font-weight: $font_weight_light;
      display: block;
      opacity: 0.6;
      margin-bottom: $offset / 2;
      font-size: 60%;
    }
  }
}