.modal2 {
  position: fixed;
  left: 0;
  top: 48px;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,0,0.4);
}
