.message {
  background: $primary_color_bg_darker;
  padding: $offset $offset * 2 $offset;
  margin-bottom: $offset * 3;
  border-radius: $radius * 2;
  position: relative;

  $triangle_dim: 1rem;
  &::before {
    @include triangle(left, $primary_color_bg_darker, $triangle_dim);

    border-bottom-width: 0;
    position: absolute;
    right: 100%;
    bottom: 0;
  }

  &-time {
    font-size: 90%;
    padding-top: $offset;
    opacity: 0.35;
  }

  &-user {
    margin-right: 125px;
    border-bottom-left-radius: 0;
  }

  &-self {
    background: $primary_color;
    color: $white;
    margin-left: 125px;
    border-bottom-right-radius: 0;

    &::before {
      @include triangle(right, $primary_color, $triangle_dim);

      right: auto;
      left: 100%;
      border-bottom-width: 0;
      border-right-width: 0;
    }
  }
}