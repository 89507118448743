.modal-inner > .flex-grid-container,
.modal-inner > div > .flex-grid-container,
.flex-grid-container.max-height,
.max-height-container > .flex-grid-container {
  max-height: calc(100vh - 98px);
  min-height: calc(100vh - 98px);
}

.max-height-content-container > .flex-grid-container {
  max-height: calc(100vh - 168px);
}

.flex-grid-container {
  display: flex;
  height: 100%;
  overflow: hidden;

  &.flex-row {
    flex-direction: row;

    & > * {
      flex-direction: row;
    }
  }

  &.border-radius { border-radius: $radius; }

  &.inner-border {
    & > div {
      & + div {
        border-left: solid 1px $border_color;
      }
    }
  }

  & > * {
    background: $white;
    // flex: 0 0 50%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.w-33 { flex-basis: 33.33333%; }
    &.w-50 { flex-basis: 50%; }
    &.w-66 { flex-basis: 66.66666%; }
    &.w-100 { flex-basis: 100%; }

    &.justify-center { justify-content: center; }

    &.gutter-left { margin-left: $offset * 2; }
    &.gutter-right { margin-right: $offset * 2; }

    &.border-radius { border-radius: $radius; }

    .use-space {
      flex-grow: 1;

      &.parent {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .scrollable {
    overflow-y: scroll !important;
  }

  .scrollable-parent {
    overflow-y: hidden !important;
  }

  .scrollable-height-100 {
    height: 100%;
  }
}