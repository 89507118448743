.create-order {
	display: flex;
	
	.create-order-inner {
		flex: 0;
		display: flex;
		
		&:nth-child(1) {
			flex-basis: 66.66%; margin-right: $offset * 2;

			& > div {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 50%;
			}
		}
		&:nth-child(2) { flex-basis: 33.33%; margin-left: $offset * 2; }

		& > div {
			flex: 1;
			display: flex;
			flex-direction: column;
			min-width: 0;

			& + div {
				border-left: solid 1px $border_color;
			}
		}
	}
}