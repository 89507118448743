@mixin toastify-variant($color: $base_text_color, $bg: #fff) {
  background: $bg;
  color: $color;

  .Toastify__close-button {
    color: $color;
  }

  .Toastify__progress-bar {
    background: rgba($color,0.25);
  }
}

.Toastify__toast {
  min-height: 52px;
  border-radius: $radius;

  &-body {
    padding-left: $offset;
    padding-right: $offset;
  }

  .Toastify__progress-bar {
    height: 3px;
  }

  &--info { @include toastify-variant($color_info, $color_info_bg); }
  &--success { @include toastify-variant($color_success, $color_success_bg); }
  &--warning { @include toastify-variant($color_warning, $color_warning_bg); }
  &--error { @include toastify-variant($color_error, $color_error_bg); }
}