/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('#{$font_path}roboto-v18-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('#{$font_path}roboto-v18-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font_path}roboto-v18-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font_path}roboto-v18-latin-100.woff') format('woff'), /* Modern Browsers */
       url('#{$font_path}roboto-v18-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font_path}roboto-v18-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('#{$font_path}roboto-v18-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
       url('#{$font_path}roboto-v18-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font_path}roboto-v18-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font_path}roboto-v18-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font_path}roboto-v18-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font_path}roboto-v18-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}