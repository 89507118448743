.header {
	background: $primary_color_highlight;
	color: $white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	// padding: $offset * 1.5 $offset * 4;
	padding: 0 $offset * 4;

	@include z-index(header);
	
	&-wrap {
		height: 49px;
		background: $primary_color;
	}
	
	&-action {
		float: right;
		// line-height: 1;
		// padding-top: 2px;
		display: flex;
		align-items: center;

		& > * {
			margin: 0 $offset / 2;
		}

		& > i,
		.header-action-trigger {
			margin: 0 $offset * 1.5;
			line-height: 1;
			
			@include font-size($font_size_big);
		}
	}

	&-version {
		opacity: 0.5;

		@include font-size(10);
	}
}