$font_path: './fonts/';
$icon_font_path: './fonts/';

$radius: 6px;
$panel_width: 300px;

$font_family: 'Roboto', 'Arial', 'Helvetica', sans-serif;
$font_weight_base: $font_weight_normal;
$font_size_small: 13;
$font_size_big: 20;

$font_weight_medium: 700;

$z-index: (
	table_header		: 10,
	sticky_headline		: 10,
	triangle			: 10,

	header				: 1001,
	panel				: 1011,
	panel_trigger		: 1012,

	order_datail_view_overlay: 2001,
	order_datail_view		 : 2002,

	modal_overlay	: 3001,
	modal	 		: 3002,
	modal_close 	: 3004,
	modal_inner 	: 3005,

	tooltip	: 4005,

	full_screen_loader	: 5005,
);