/*
	// Usage:
	p {
		@include font-size(13);
	}
*/
@mixin font-size($size_value: $font_size_rem) {
	//font-size: ($size_value * 10) + px;
	//font-size: $size_value + rem;
	
	font-size: $size_value + px;
	font-size: ($size_value / 10) + rem;
}