.header-navi {
  float: left;

  ul {
    @include reset_list();

    li {
      display: inline-block;
    }
  }

  a {
    color: $white;
    display: block;
    background: transparent;
    padding: $offset * 2.25 $offset * 4 $offset * 2;
    text-decoration: none;
    transition: .2s;
    text-transform: uppercase;
    outline: none;

    i {
      margin-right: $offset / 2;
    }

    &:hover,
    &.active {
      background: #47A3FF;
    }
  }
}